.msgWrapper {
    width: auto;
    background: var(--N20);
    border-radius: 8px;
    margin:9px auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 6px 13.33px 6px 13.3333px;
    gap: 0.75rem
   
}

.error {
    border-color: #E02020 !important;
}

.warning {
    border-color: #FFB200 !important;
}