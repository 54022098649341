.gallery-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  padding: 0.1rem 0rem;
  background: var(--N30);
  gap: 0.5rem;
}

.gallery-grid-box {
  display: grid;
  width: 100%;
  overflow: hidden;
  gap: 0.25rem;
  border-radius: 0.3rem;
  padding: 0.4rem;
}

.grid-small {
  grid-template-columns: repeat(4, 1fr);
  grid-column-start: 1;
  grid-column-end: 5;
}
.grid-medium {
  grid-template-columns: 1fr 1fr;
  grid-column-start: 1;
  grid-column-end: 3;
}
.grid-large {
  grid-template-columns: auto;
  grid-column-start: 1;
  grid-column-end: 2;
}

.img-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  gap: 0.25rem;
  border-radius: 0.3rem;
  padding: 0.4rem;
}
.img-container-frame {
  background-color: var(--N0);
}

.img-container-small {
  min-width: 23%;
  max-width: 23%;
}

.img-container-large {
  min-width: 96%;
  max-width: 96%;
}

.img-container-medium {
  min-width: 48%;
  max-width: 48%;
}

.image {
  display: flex;
  border-radius: 0.3rem;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}
