.inputElement {
    border: 2px solid transparent;
    outline: 2px dotted #999;
    margin-bottom: 10px;
    border-radius: 6px;
}

.inputElement:hover, .inputElement:focus {
    outline: 2px dotted transparent;
    border: 2px solid #878FDE;
}

.inputElement::placeholder {
    color: #fff;
    opacity: 0.6;
}