.lsq-switch{
    width: 38px !important;
    height: 19px !important;
}

.p-inputswitch.lsq-switch-v2{
    width: 38px !important;
    height: 19px !important;
    border-radius: 100px !important;
}

.p-inputswitch.lsq-switch-v2 .p-inputswitch-slider:before{
    top: 1px !important;
    left: 1.5px !important;
    margin: 0px !important;
    width: 17px !important;
    height: 17px !important;
}

.p-inputswitch.lsq-switch-v2.p-inputswitch-checked .p-inputswitch-slider:before {
    background: #fff !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2) !important;
    transform: translateX(18px) !important;
}


.lsq-switch .p-inputswitch-slider:before {
    top: 1px !important;
    left: 1.5px !important;
    margin: 0px !important;
    width: 17px !important;
    height: 17px !important;
}

.lsq-switch.p-disabled{
    opacity: 0.4 !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before { 
    background: #314361 !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2) !important;
}
.casa-web-view-app .lsq-switch-v2{
        width: 38px !important;
        height: 19px !important;
}
.casa-web-view-app .p-inputswitch.lsq-switch-v2.p-inputswitch-checked .p-inputswitch-slider {
    background: #000 !important;
}

.casa-web-view-app  .p-inputswitch.lsq-switch-v2 .p-inputswitch-slider:before{
    top: 1px !important;
    left: 1.5px !important;
    margin: 0px !important;
    width: 17px !important;
    height: 17px !important;
}
.casa-web-view-app .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    background: #fff !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2) !important;
    transform: translateX(18px) !important;
}

.p-inputswitch.lsq-switch-v2.p-inputswitch-checked .p-inputswitch-slider {
    background: #000 !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider{
    background: #000 !important;
}