.lead-action-preview-backgrond {
  width: 25rem;
  background: rgba(255, 255, 255, 0.5);
  height: 95%;
  border-radius: 0 0 20px 20px;
}

.lead-quick-action-preview {
  height: 45%;
  width: 95%;
  background-color: white;
  border-radius: 20px;
}

.lead-actions-fields {
  box-shadow: 0px 0px 4px 4px #1818180a;
  border-radius: 5px;
  background-color: #ffffff;
}

.quick-action-field {
  height: 6rem;
  width: 31%;
  flex-basis: 31%;
  border-radius: 10px;
  background-color: #ffffff;
}

.selected-quick-action {
  border: 1px solid #878fde;
}

.quick-action-edit {
  background: #ebecfa;
}

.quick-action-delete {
  background: var(--R100);
}

.quick-action-drag-indicator {
  left: -1.3rem;
  top: 2rem;
  height: 2rem;
  width: 1.3rem;
  background-color: #878fde;
  border-radius: 5px 0 0 5px;
  position: absolute;
}

.delete-edit-action {
  border-radius: 10px;
  opacity: 0;
  transition: opacity 0.2s;
}

.quick-action-field:hover .delete-edit-action {
  opacity: 1;
}
