.image-gallery{
    background-color: #F6F6F7;
    border-radius: 0.5rem;
}

.img-preview-container{
    width: 30%;
    height: 2rem;
    overflow: hidden;
    border-radius: 0.3rem;
}