.scroll-top-icon {
  position: absolute;
  right: 1.3125rem;
  bottom: 2.125rem;
  background-color: var(--N0);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 0.5rem;
  visibility: hidden;
}
.scroll-top-icon > svg path {
  fill: var(--N100);
}

.scrollTop_fadeIn {
  transition: opacity 350ms;
}
.scrollTop_fadeOut {
  opacity: 0;
  transition: all ease-in-out 350ms;
}

.subHeader_fadein {
  visibility: visible;
  transition: all ease-in-out 350ms;
  background-color: var(--N0);
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.12);
}
.subHeader_fadeout {
  visibility: hidden;
  transition: all ease-in-out 350ms;
}