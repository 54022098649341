.icon-picker-items {
  background-color: var(--N20);
  border-radius: 8px;
  padding: 0.6rem;
}
.icon-picker-items:hover {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.24);
  background-color: var(--primary) !important;
}
.icon-picker-items:hover img {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(162deg)
    brightness(101%) contrast(104%);
}
