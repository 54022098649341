.widget-library {
  padding: 0rem 0.5rem;
  background-color: var(--N20);
  position: relative;
}
.widget-library-header {
  border-bottom: 2px solid var(--N40);
  position:absolute;
  backdrop-filter: blur(6px);
  --webkit-backdrop-filter: blur(6px);
  z-index: 1;
  padding: 0rem 1rem;
}
.widget-library-sub-header {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 2rem;
  transition : "all ease-in-out 500ms";
}
.widget-wrapper {
  border-bottom: 2px solid var(--N40);
}
.widget-wrapper:last-child {
  border-bottom: none;
}
.widget-content {
  height: 100%;
  gap: 0.5rem;
}
.lab:hover > svg path {
  transition: 300ms;
  fill: var(--N400);
}
.lab > svg path {
  transition: 300ms;
}

.lab:hover {
  background-color: var(--N0);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
}
.lab {
  height: 2rem;
  width: 2rem;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 0.25rem;
}
.search-bar{
  height: 2rem !important;
  width: 95% !important;
  background: var(--N0) !important;
  border: none !important;
  padding-top: 0.65rem !important;
}
.search-bar::placeholder {
  font-family: "Nunito",sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 0.875rem !important;
  line-height: 1.188rem !important;
  color: var(--N60) !important;
}
.search-bar:enabled:focus {
  border: none !important;
}

.widget-feedback-icon{
  background-color: var(--N0);
  border-radius: 10px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
}

.addedBadge{
  font-family: "Nunito", sans-serif;
  height: 26px;
  font-size: 14px;
  display: inline-flex;
  gap: 5px;
  align-items: center;
  padding: 6px 10px;
  background: #C6F1C9;
  color: #04A410;
  font-weight: 700;
  border-radius: 8px;
}