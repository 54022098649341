.calendar-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  background-color: white;
  border-radius: 12px;
  /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05); */
  width: 320px;
  overflow: hidden;
}

.calendar-title {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #292929;
  gap: 4px;
}

.calendar-title img {
  width: 18px;
  height: 18px;
}

.calendar-widget-svg g {
  opacity: 1;
}

.calendar-widget-svg path {
  fill: #292929;
}

.calendar-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--N30);
  padding: 0px 6px 0px;
  border-radius: 8px;
  background: var(--N10);
}
.calendar-nav svg {
  width: 14px;
  height: 14px;
}

.calendar-date {
  font-size: 12px;
  color: #333;
  font-weight: 500;
  cursor: pointer;
}

.nav-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #999;
  padding: 0;
}

.tab-container {
  display: flex;
  border-bottom: 1px solid #f0f0f0;
}

.tab {
  flex: 1;
  text-align: center;
  padding: 12px;
  cursor: pointer;
  font-size: 14px;
  color: #666;
  font-weight: 500;
  transition: all 0.3s ease;
}

.tab.active {
  color: #007aff;
  border-bottom: 2px solid #007aff;
}

.timeline,
.agenda {
  display: none;
}

.timeline.active,
.agenda.active {
  display: block;
  background-color: var(--N20);
  padding: 8px;
  border-radius: 0px 0px 8px 8px;
}

.time-slot {
  display: flex;
  margin-bottom: 24px;
}

.time {
  width: 40px;
  font-size: 12px;
  color: #999;
  text-align: left;
  padding-right: 16px;
  font-weight: 500;
}

.event {
  flex: 1;
  background-color: var(--N0);
  border-left: 3px solid #007aff;
  padding: 16px 16px 16px 0px;
  border-radius: 12px;
  font-size: 11px;
}

.event-title {
  font-weight: 600;
  color: #333;
  margin-bottom: 2px;
}

.event-person {
  color: #666;
}

.event-icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  vertical-align: middle;
  font-weight: bold;
  color: white;
  font-size: 12px;
}

.agenda-item {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding: 8px;
  border-radius: 6px;
  background-color: #f8f8f8;
}

.agenda-time {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  width: 60px;
}

.agenda-event {
  flex: 1;
  margin-left: 12px;
}

.agenda-title {
  font-weight: 600;
  color: #333;
  margin-bottom: 2px;
}

.agenda-person {
  font-size: 12px;
  color: #666;
}

#datepicker {
  display: none;
  position: absolute;
  top: 70px;
  left: 20px;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

#datepicker table {
  border-collapse: collapse;
  width: 100%;
}

#datepicker th,
#datepicker td {
  padding: 8px;
  text-align: center;
  font-size: 12px;
}

#datepicker th {
  font-weight: 600;
  color: #666;
}

#datepicker td {
  cursor: pointer;
  color: #333;
}

#datepicker td:hover {
  background-color: #f0f0f0;
  border-radius: 50%;
}

#datepicker .month-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

#datepicker .month-nav button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #333;
}

.timeline-header {
  background: var(--S800);
  color: white;
  border-radius: 8px;
  border: 1px solid var(--N30);
  font-size: 10px;
}

.scroll-button {
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #878fde;
  border-radius: 4px;
  color: white;
  transition: all 0.3s ease;
}
.scroll-button svg {
  height: 14px;
  width: 14px;
}
.scroll-button:hover {
  background: #878fde;
}
.todo-container {
  display: flex;
  background-color: var(--N20);
  gap: 8px;
  padding: 8px;
  overflow: scroll;
  border-radius: 0px 0px 7px 7px;

}
