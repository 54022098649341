.homepages-view {
  border: 1px solid #f5f6f8;
  border-radius: 0.5rem;
  box-shadow: 0px 2px 8px 0px #00000014;
}

.action-bar{
  border-bottom: 1px solid var(--N30);
}

.title-bar {
  border-bottom: 1px solid var(--N60);
}