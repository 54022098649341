.entityTreePanel .p-treenode-content{
    pointer-events: none !important;
    padding: 0px !important;
    margin-left: 0px !important;
}

.entityTreePanel .p-treenode-content .p-checkbox{
    display: none;
}

.entityTreePanel .p-treenode-leaf .p-treenode-content{
    pointer-events: all !important;
    padding: 0px !important;
    gap: 0.2rem !important;
}

.entityTreePanel .p-treenode-label{
    overflow-x: scroll;
}

.entityTreePanel .p-treenode-leaf .p-treenode-content .p-checkbox{
    display: flex;
}

.work-area .pi-chevron-down {
    color: #768196 !important;
    font-size: 0.7rem !important;
}

.entityTreePanel .p-checkbox {
    align-items: center !important;
    margin-right: 3px !important;
    justify-content: center !important;
}

.entityTreePanel .p-checkbox .p-checkbox-box {
    border-radius: 50% !important;
    height: 16px !important;
    width: 16px !important;
    border: 1px solid #627087 !important;
}
.entityTreePanel .p-checkbox-box.p-highlight {
    background-color: #314361 !important;
    border-color: #314361 !important;
}

.entityTreePanel .p-treenode-leaf .p-checkbox-box.p-highlight {
    background-color: #314361 !important;
    border-color: #314361 !important;
}
  
.entityTreePanel .p-treeselect-empty-message{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
    font-family: 'Nunito', sans-serif;
}
.entityTreePanel .pi-check{
    font-size: 0.7rem !important;
}