body {
  margin: 0;
  /* font-family: "Poppins", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Mulish', sans-serif;
  font-size: 16px;
}

* {
  padding: 0;
  margin: 0;
  scrollbar-width: none;
}

:root {
  --N600: #0a1f43;
  --N500: #283a5a;
  --N400: #314361;
  --N300: #3b4c69;
  --N200: #43536f;
  --N100: #627087;
  --N90: #768196;
  --N80: #8a94a5;
  --N70: #9da5b4;
  --N60: #b0b7c2;
  --N50: #c4c9d2;
  --N40: #d8dbe1;
  --N30: #ecedf0;
  --N20: #f5f6f8;
  --N10: #fafbfb;
  --N0: #ffffff;
  --B800: #0393F7;
  --B700: #1d6ed2;
  --B600: #68C0FD;
  --B500: #5592dd;
  --B400: #71a4e3;
  --B300: #aac8ee;
  --B200: #c6daf3;
  --B100: #E6F5FF;
  --Y600: #FFC338;
  --Y400: #FFD471;
  --Y100: #FFF6E2;
  --G400: #7DCA7D;
  --R100: #FFE6E6;
  --R700: #FB2323;
  --R800:  #FA0303;
  --error : #D62839;
  --S800 : #303DB1;
  --S600 : #878FDE;
  --S100 : #EBECFA;
  --green-500:green;

  --focused: var(--N600);
  --primary: var(--N400);
  --tertiary: var(--N200);
  --btn-focused: var(--N600);
  --btn-primary: var(--N400);
  --btn-tertiary: var(--N200);
  --btn-disabled: var(--N50);
  --text-focused: var(--N600);
  --text-primary: var(--N400);
  --text-tertiary: var(--N200);
  --sub-text: var(--N90);
  --icon-focused: var(--N600);
  --icon-primary: var(--N400);
  --icon-tertiary: var(--N200);
  --icon-disabled: var(--N50);
}

.p-menu {
  width: 35px !important;
  border: 1px solid #e2ecf9 !important;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  transform: translate(-30%, 9%) !important;
}

@media only screen and (min-width: 1444px) {
  html {
    font-size: 17px;
  }
}

@media only screen and (max-width: 1444px) {
  html {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1200px) {
  html {
    font-size: 15px;
  }
}

@media only screen and (max-width: 984px) {
  html {
    font-size: 14px;
  }
}
