.wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.custom-checkbox .p-checkbox .p-checkbox-box{
    border-radius: 3px !important;
    height: 12px !important;
    width: 12px !important;
    border: 1px solid #627087 !important;
}

.custom-checkbox .label{
    font-weight: 400;
    font-size: 10px;
    color: var(--N200);
    font-family: 'nunito', sans-serif;
    align-self: center;
}

.custom-checkbox .p-checkbox .p-checkbox-icon{
    font-size: 0.6rem !important;
}

