.status-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #ffffff;
  width: 100%;
  height: 4%;
  min-height: 18px;
  padding: 1% 0;
  z-index: 2;
}

.status-bar-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: transparent;
  height: 100%;
  margin-right: 4%;
  width: min-content;
  column-gap: 2px;
}

.time {
  font-family: "Segoe UI", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  color: #0a1f43;
  display: flex;
  align-items: flex-end;
  width: min-content;
  justify-content: center;
  margin-left: 4%;
}

.quick-actions-background {
  height: 100%;
  width: 100%;
  justify-content: end;
  align-items: center;
  background-color: #03030380;
  backdrop-filter: blur(2px);
  z-index: 9;
}

.lsq-slider {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
}

.slide {
  position: absolute;
  transition: 0.5s;
  filter: blur(1px);
}

.prev-slides {
  transform: translateX(-120px) scale(0.9);
}

.next-slides {
  transform: translateX(120px) scale(0.9);
}

.active-slide {
  filter: none;
  transform: translateX(0);
  z-index: 9;
}
