.custom-image-input-v2 .p-button {
  background: var(--N20) !important;
  border: 1px dashed #d7d8db !important;
  padding: 8px 12px !important;
  display: flex;
  justify-content: center;
  width: 100%;
  color: var(--N60) !important;
  border-radius: 0.5rem !important;
}

.custom-image-input-v2 .p-button-label {
  color: var(--N60) !important;
}

.secondary .custom-image-input-v2 .p-button{
  background: var(--N0) !important;
  color: var(--N400) !important;
  border: 1px dashed var(--N60) !important;
  
}
.secondary .custom-image-input-v2 .p-button-label {
  color: var(--N400) !important;
}
.secondary .custom-image-input-v2 .p-button-label span{
color:var(--R700) !important;
}

