body.active-modal {
  overflow: hidden;
}

.banner-box {
  background: #ffffff;
  box-shadow: 0px 3px 14px rgba(49, 67, 97, 0.24);
  border-radius: 12px;
  overflow: hidden;
  padding: 1rem;
  width: 30vw;
  animation: fadein 0.8s;
}

@keyframes fadein {
  0% {
    opacity: 0;
    transform: translateY(-50px, -50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.banner {
  z-index: 10;
}
.banner-background{
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(22px);
  -webkit-backdrop-filter: blur(22px);
}