.p-treeselect-header {
    display: none !important;
}

.p-tree {
    padding: 0.5rem !important;
}

.pi-minus:before {
    display: none !important;
}

::-webkit-scrollbar {
    width: 8px !important;
}

::-webkit-scrollbar-track {
    background: transparent !important;
}

::-webkit-scrollbar-thumb {
    background: #D8DBE1 !important;
    border-radius: 8px !important;
}

.lsq-tree-select ~ .gap-1 {
    gap: 4px !important;
}

.p-treenode-content {
    padding: 2px 9px !important;
    margin: 0px !important;
}

.p-treenode-label{
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 0.625rem !important;
    line-height: 0.75rem !important;
    display: flex !important;
    align-items: flex-end !important;
    letter-spacing: -0.02em !important;
    color: #283A5A !important;
    max-width: 35ch;
}


.p-treeselect-label-container{
    height: 2.5rem !important;
}

.p-treeselect-label{
    height: 100%  !important;
    display: flex !important;
    align-items: center !important;
}

.p-chip-text {
    font-family: "Nunito", sans-serif !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 0.625rem !important;
    line-height: 0.875rem !important;
    color: var(--N600) !important;
}

.lsq-tree-select .pi-chevron-down{
    color: #768196 !important;
    font-size: 0.7rem;
}

.p-highlight{
    background-color: #ffffff !important;
}

.p-treenode-leaf .p-treenode-label{
    font-family: 'Nunito', sans-serif;
    font-weight: 400 !important;
}

.p-checkbox{
    align-items: center !important;
}

.p-checkbox .p-checkbox-box{
    border-radius: 3px !important;
    height: 18px !important;
    width: 18px !important;
    border: 1px solid #627087 !important;
}
.p-checkbox-box.p-highlight{
    background-color:var(--N600) !important;
    border-color:var(--N600) !important;
}

.p-treenode-leaf .p-checkbox-box.p-highlight{
    background-color:var(--N100)!important;
    border-color:var(--N100)!important;
}
.p-treeselect:not(.p-disabled):hover {
    border-color: var(--N600) !important;
}

.pi-fw{
    font-size: 0.7rem !important;
}


.p-treenode-content:focus{
    box-shadow: none !important;
}

.p-tree-toggler:focus{
    box-shadow: none !important;
}
.lsq-tasktype-panel .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler, .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
    color:  var(--N600) !important;
}
