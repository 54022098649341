.custom-dropdown-input-Text {
  background: #ffffff;
  padding: 0.75rem 0.45rem 0.45rem 0.45rem;
  appearance: none;
  border-radius: 6px;
  border: 1px solid var(--N40);
}

.custom-dropdown-field-label {
  position: absolute;
  margin-top: -0.5rem;
  left: 0.75rem;
  background: white !important;
  padding: 0 5px;
}
