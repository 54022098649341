.cover-page {
  background: var(--N20);
  position: relative;
  transition: background-color 0.3s ease;
  height: 150px;
  min-height: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cover-page::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #878fde; /* Blue overlay */
  opacity: 0;
  transition: opacity 0.3s ease;
  box-sizing: border-box;
}
.cover-page:hover::before {
  opacity: 1;
}
.coverImageElement:hover {
  border: 2px solid #878fde;
}
.webCoverPageContainer {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 1;
}

.webCoverPageContainerPreview {
  position: absolute;
  width: 82%;
  top: 15px;
  z-index: 1;
}
.cover-page .coverpage {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}
.coverImageElement{
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  filter: brightness(0.7);
  top: 0px;
  left: 0px;
}
