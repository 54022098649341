.theme-configurator {
  padding: 0rem 0.8rem;
  background-color: var(--N20);
  position: relative;
}

.theme-configurator-tab {
  background-color: #ffffff;
  border-radius: 10px;
}

.status-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: var(--N0);
  width: 100%;
  height: 4%;
  min-height: 18px;
  padding: 1% 0;
}

.status-bar-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: transparent;
  height: 100%;
  margin-right: 4%;
  width: min-content;
  column-gap: 2px;
}

.time {
  font-family: "Segoe UI", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  color: #0a1f43;
  display: flex;
  align-items: flex-end;
  width: min-content;
  justify-content: center;
  margin-left: 4%;
}

.tile-block {
  display: grid;
  gap: 0.5rem;
  width: 100%;
  height: fit-content;
}

.tab-count-5 {
  grid-template-columns: repeat(6, 1fr);
}

.tab-count-5 .tile-view:nth-child(-n + 2) {
  grid-column: span 3;
}

.tab-count-5 .tile-view:nth-last-child(-n + 3) {
  grid-column: span 2;
}

.tile-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid var(--N30);
  padding: 0.5rem;
  position: relative;
  background-color: white;
  height: 4.5rem;
}

.tile-icon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.tile-icon-background {
  padding: 0.75rem;
  margin-left: 5px;
  border-radius: 50%;
  background-color: var(--N20);
  top: 0.4rem;
  right: 0.3rem;
  opacity: 0.2;
}

.custom-accordion-block {
  border-radius: 8px;
  border: 1px solid var(--N30);
  align-items: center;
}

.vertical-divider {
  width: 97%;
  border: 0.5px solid var(--N40);
}

.custom-dropdown-input-Text {
  background: #ffffff;
  padding: 0.65rem;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 6px;
}

.custom-dropdown-field-label {
  position: absolute;
  margin-top: -0.5rem;
  left: 0.65rem;
  background: white !important;
  padding: 0 5px;
}
.mobile_preview_icons path {
  fill: currentColor;
}

.toast_container {
  position: absolute;
  width: 14.563rem;
  right: 0.4rem;
  top: -2rem;
}

.custom-theme-container {
  padding: 0.45rem;
  border-radius: 8px;
  border: 1px solid var(--N30);
}

.custom-color-selector{
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  transition: height 0.1s ease;
  transition: padding 0.1s ease;
  padding-top: 4px;
}

.custom-color-selector.hide{
  height: 0px;
  padding-top: 0px;
}
