.configuration-screen-wrapper{
    height: 96%;
    border-radius: 1rem;
}

.title-bar{
    border-bottom: 1px solid #B0B7C2;
}


