.lsq-calendar {
  font-family: "Nunito", sans-serif !important;
}

.lsq-calendar .p-inputtext {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 0.75rem !important;
  line-height: 1rem !important;
  display: flex;
  align-items: flex-end;
  letter-spacing: -0.02em;
  color: var(--N500);
}