.container{
    border: 1px solid #D8DBE1;
    border-radius: 6px;
}
.img-container{
    border-radius: inherit;
    width: 100%;
}
.Square{
    height: 34vh;
}
.Wide{
    height: 17vh;
}

.draghandle-position{
    left: auto;
}

.edit-container{
    box-sizing: border-box;
    padding: 8px 8.5px;
    background: rgba(255, 255, 255, 0.5);
    bottom: 0;
    border-radius: 0px 0px 6px 6px;
    backdrop-filter: blur(2px);
}

.banner-img-name{
    width: 75%;
}
