.item-tile {
  box-shadow: 0px 0px 4px 4px #1818180a;
  border-radius: 5px;
  background-color: #ffffff;
}
.addedBadge{
  font-family: "Nunito", sans-serif;
  font-size: 10px;
  display: inline-flex;
  gap: 5px;
  align-items: center;
  padding: 3px 8px;
  background: #C6F1C9;
  color: #04A410;
  font-weight: 700;
  border-radius: 4px;
}