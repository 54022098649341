.banner-container {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: space-between;
    border-radius: 8px;
  }
  .banner-container.show-frame{
    padding: 4px;
    background-color: white;
    box-shadow: 0px 2px 8px 0px #00000014;
  }
  
  .banner-container .image-container {
    display: block;
    height: 100%;
    overflow-y: auto;
  }
  .banner-container img {
    object-fit: cover;
    display: flex;
    flex: 1 0 auto;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    border-radius: 4px;
  }
  .banner-name {
    text-align: center;
    font-size: 12px;
    color: #4b5563;
    overflow: hidden;
  }
  .banner-container.navigate-to {
    cursor: pointer;
  }
  