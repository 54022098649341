.lsq-radio{
    width: 1rem !important;
    height: 1rem !important;
}

.lsq-radio .p-radiobutton-box{
    border-color: var(--N600) !important;
    width: 1rem !important;
    height: 1rem !important;
}

.lsq-radio .p-radiobutton-box.p-highlight:not(.p-disabled):hover{
    background: #FFFFFF !important;
}

.lsq-radio .p-radiobutton-box.p-highlight{
    background-color: #FFFFFF !important;
}

 .lsq-radio .p-radiobutton-box .p-radiobutton-icon{
    background-color: var(--N600) !important;
    width: calc(1rem - 10px) !important;
    height: calc(1rem - 10px)  !important;
}

 .lsq-radio .p-radiobutton-box:not(.p-disabled).p-focus{
    box-shadow: none !important;
}

.lsq-radio .p-disabled {
  border-color: var(--N50) !important; 
}

.lsq-radio .p-disabled .p-radiobutton-icon {
  background-color: var(--N50) !important; 
}
.lsq-radio .p-radiobutton-box:not(.p-disabled):not(.p-highlight) {
    border-color: var(--N90) !important; 
}