.webview-widget {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: white;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 12px;
}
.webview-widget .navigate-button {
  position: absolute;
  border: 0px;
  background: #00000099;
  display: flex;
  border-radius: 0px 8px 0px 8px;
  right: 0;
  top: 0px;
  cursor: pointer;
  padding: 4px;
  z-index: 1;
  color: white;
}

.webview-widget .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  border-bottom-width: 1px;
  border-color: #dfe2e6;
  padding-right: 0;
  margin-right: 30px;
  color: #212b36;
  font-size: 16px;
}

.webview-widget .frame {
  flex-grow: 1;
  overflow: auto;
  border-radius: 8px;
  width: inherit;
}
.webview-widget .frame iframe {
  border: none;
}
.webview-widget .zoom-controls {
  position: absolute;
  right: 1px;
  bottom: 0px;
  border-radius: 8px 0px 8px 0px;
  overflow: hidden;
  display: flex;
}
.webview-widget .zoom-controls div {
  display: flex;
  background: #00000099;
  padding: 4px;
  color: white;
  width: 28px;
  height: 28px;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.webview-widget .zoom-controls div:first-child {
  border-right: 1px solid #878b92;
  transform-origin: 0 0;
}
.webview-widget .web-view-wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 1;
}
