.tab-count-2,
.tab-count-3 {
  height: 5rem;
}

.tile-view {
  border-radius: 8px;
  width: 100%;
  border: 1px solid var(--N30);
  padding: 0.5rem;
  background: var(--N0);
  cursor: pointer;
}

.detailed-widget-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
}

.detailed-widget-container:hover,
.active-widget {
  border: 1px solid #878fde;
}
.tile-view:hover,
.active-widget {
  border: 1px solid #878fde;
}

.drag-indicator-background {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #878fde;
  border-radius: 5px 5px 0 0;
  width: 3rem;
  height: 1rem;
}

.drag-indicator {
  opacity: 0;
}
.active-widget .drag-indicator {
  opacity: 1;
}

.tile-view:hover .drag-indicator {
  opacity: 1;
}

.detailed-widget-container:hover .drag-indicator {
  opacity: 1;
}
