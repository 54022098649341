.collapsed-view {
  height: 0;
  display: none;
}

.expanded-view {
  height: 100%;
  transition: all 0.6s ease-in;
}

.item-tile {
  box-shadow: 0px 0px 4px 4px #1818180a;
  border-radius: 5px;
  background-color: #ffffff;
  height: fit-content;
}

.tile-actions {
  opacity: 0;
  transition: opacity 0.2s;
}

.item-tile:hover .tile-actions {
  opacity: 1;
}
