.view-box {
  height: 96%;
  border-radius: 1.25rem;
  box-shadow: 0px 3px 14px 0px #3143611f;
  overflow: hidden;
  background-color: #ffffff;
}

.title-bar {
  border-bottom: 1px solid #ecedf0;
}

.lsq-card {
  height: 3.125rem;
  cursor: pointer;
  padding: 0.6rem;
}
.lsq-card:hover {
  border-radius: 0.625rem;
  box-shadow: 0px 3px 14px 0px #3143611f;
}
