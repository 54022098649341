.container {
  position: absolute;
  margin-left: -6.2rem;
  z-index: 10000;
}

.menu_container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #d8dbe1;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  padding: 0.4rem 0rem;
  cursor: pointer;
  z-index: 10;
}
.menu_item {
  box-sizing: border-box;
  width: 7.5rem;
  padding-top: 0.45rem;
  padding-bottom: 0.45rem;
  padding-left: 1rem;
}
.menu_item:hover {
  background-color: rgba(231, 231, 231, 0.762);
}
