.lsq-icon-picker.p-dropdown .p-dropdown-label.p-placeholder {
  font-family: "Nunito", sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 0.75rem !important;
  line-height: 1rem !important;
  letter-spacing: -0.02em !important;
}

.lsq-icon-picker.p-dropdown .p-dropdown-label{
  display: flex;
  align-items: center;
  justify-content: center;
}

.lsq-icon-picker.p-dropdown .p-dropdown-trigger {
  width: auto !important;
  margin-right: 0.5rem !important;
}

.lsq-dropdown-input-text {
  font-family: "Nunito", sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 0.75rem !important;
  line-height: 1rem !important;
  display: flex;
  align-items: flex-end;
  letter-spacing: -0.02em;
  color: var(--N500);
}

.lsqIconPickerPanel .p-dropdown-items {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-content: center;
  padding: 0.75rem !important;
  gap: 1rem;
}

.lsqIconPickerPanel .p-dropdown-items .p-dropdown-empty-message {
  grid-column: span 5;
}

.lsqIconPickerPanel .p-dropdown-items > * {
  padding: 0 !important;
}

.lsqIconPickerPanel .p-dropdown-items .p-dropdown-item:hover {
  background-color: var(--N0) !important;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.24);
}

.lsqIconPickerPanel .pi-chevron-down {
  color: var(--N90);
  font-size: 0.7rem;
}
