.sections {
  width: 93.33%;
}

.mobile-screen.disabled{
  pointer-events: none;
}

.section-delete-drop {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 45vh;
  width: 45vh;
  bottom: 0;
  left: 0;
  transform: translate(-50%, 50%);
  z-index: 10;
}

.section-delete-drop > ::-webkit-scrollbar {
  display: none;
}

.status-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #ffffff;
  width: 100%;
  height: 4%;
  min-height: 18px;
  padding: 1% 0;
}

.status-bar-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: transparent;
  height: 100%;
  margin-right: 4%;
  width: min-content;
  column-gap: 2px;
}

.time {
  font-family: "Segoe UI", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  color: #0a1f43;
  display: flex;
  align-items: flex-end;
  width: min-content;
  justify-content: center;
  margin-left: 4%;
}

.title-bar {
  display: flex;
  width: 100%;
  background-color: #ffffff;
  height: 10%;
  flex-direction: row;
}

.home-text {
  font-family: "Segoe UI", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  font-size: 1rem;
  color: #0a1f43;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sections-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;
}

.sections-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 1;
  overflow-y: auto;
  padding: 0.6rem 0rem;
  margin-bottom: 1rem;
  gap: 0.2rem;
}

.custom-fab-screen {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0.6rem 0.6rem;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(4px);
  border-radius: 8px;
}
.custom-fab-icon {
  background: var(--primary);
  border-radius: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  transition: all ease-in-out;
}

@keyframes section-delete-locator-animation {
  0% {
    height: 70%;   
    width: 70%;    
  }
  40% {
    height: 80%;   
    width: 80%;    
  }
  60% {
    height: 60%;
    width: 60%;
  }
  80% {
    height: 75%;
    width: 75%;
  }
  100% {
    height: 70%;
    width: 70%;
  }
}

.section-delete-locator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  height: 70%;
  width: 70%;
  overflow-y: auto;
  border-radius: 50%;
  gap: 1rem;
  border: 1px solid #f3aaaa;
  animation-name: section-delete-locator-animation;
  animation-duration: 500ms;
  animation-timing-function: ease-out;
}

.section-delete-locator-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
  flex-wrap: nowrap;
  font-size: 0.5rem;
  position: absolute;
  left: 52%;
  bottom: 55%;
  text-align: center;
}

.section {
  min-height: 8rem;
  width: 94%;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  column-gap: 2%;
  flex-wrap: wrap;
  row-gap: 6px;
}

.add-new-section {
  height: 3rem;
  min-height: 3rem;
  width: 94%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.widget {
  height: 5rem;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: lightsalmon;
}

.section-drag {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.section-drag-handle {
  width: 3.1rem;
  height: 15px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: white;
  transform: translateY(10%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.row-drag {
  width: 100%;
  display: flex;
}

.row-drop {
  display: flex;
  flex-direction: row;
  width: 95%;
  margin: auto;
  justify-content: space-between;
  align-items: center;
}

.mobile-screen > ::-webkit-scrollbar {
  display: none !important;
}

.delete-icon-container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 8px;
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  cursor: pointer;
}
.toast_container {
  position: absolute;
  width: 14.563rem;
  right: 0.4rem;
  top: -2rem;
}

.discard-delete-icon{
  fill: #768196;
}

.delete-icon-container:hover > .discard-delete-icon > path{
  fill: #E75454;
}

.current-changes-icon{
  fill: #768196
}

.current-changes-icon:hover{
  fill: #314361
}

.popup-banner-screen {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  background:  #03030380;
  padding: 2rem;
}

.popup-banner-img{
  width: 100%;
  height: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
}
