.casa-web-top-bar {
  padding: 10px;
  box-shadow: 0px 2px 5px 0px #0000000a;
  border-bottom: 1px solid #efeff0;
}

.hamburger-icon {
  height: 42px;
  width: 42px;
  display: flex;
  align-items: center;
  border: 1px solid #efeff0;
  background-color: white;
  border-radius: 5px;
  box-shadow: 4px 0px 8px rgba(0, 0, 0, 0.04);
}
.left-panel {
  left: 0;
  transform: translateX(-100%);
  border-radius: 0px 12px 12px 0px;
}

.right-panel {
  right: 0;
  transform: translateX(100%);
  border-radius: 12px 0px 0px 12px;
}

.visible-panel {
  min-width: 240px;
  overflow: auto;
  transition: all 0.4s;
  transform: translateX(0%);
}

.box-animation {
  transition: 800ms;
}

.fade-in {
  opacity: 1;
  visibility: visible;
}

.fade-out {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-100%);
}
/* styles.module.css */
/* styles.module.css */
.grid-container {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  gap: 2px;
  width: 100%;
  height: 200vh;
  position: absolute;
  opacity: .5;
}
.grid-container::before {
  content: '';
  display: block;
  grid-column: 1 / span 16;
  height: 100%;
  background-image: linear-gradient(to right, transparent calc((100% / 16 - 1px)), rgba(0, 187, 221, 0.24) calc((100% / 16 - 5px)));
  background-size: calc(100% / 16) 100%;
}

.reviewChangeButtonWrapper {
  display: flex;
  align-items: center;
  background-color: #F6F6F7;
  border-radius: 8px;
  padding: 8px 12px;
  min-width: 245px;
}

.reviewChangeButtonWrapper img {
  width: 14px;
  height: 14px;
  margin-right: 12px;
}

.reviewChangeButtonWrapper > div {
  display: flex;
  flex-direction: column;
}

.reviewChangeButtonWrapper > div > div:first-child {
  color: #333;
  margin-bottom: 2px;
  font-size: 12px;
  font-weight: 700;
}

.reviewChangeButtonWrapper > div > div:last-child {
  font-size: 10px;
  font-weight: 500;
  color: #666;
}

.tabsWrapper {
  width: 388px;
  margin-left: auto;
}

.actionWrapper{
  display: flex;
  gap: 20px
}

.deleteWrapper img{
  transform: translateY(1px);
  cursor: pointer;
}

.buttonWrapper > *{
  height: 36px !important;
}

.previewButton{
  min-width: 150px;
  font-size: 12px !important;
  border-radius: 8px;
}

.previewButton [data-testid="lsq-switch-v2"] {
  width: 100%;
  justify-content: space-between;
}

.backButton [data-testid="scaffold-back-btn"]{
  width: 100% !important;
  height: 100%;
  border-radius: 4px !important;
}

.launcherAddItemButton {
  display: flex;
  border: 1px dashed #afb1b6;
  padding: 8px;
  justify-content: center;
  border-radius: 6px;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 10px;
}