.container {
    position: absolute;
    width: fit-content;
    z-index: 10000;
  }
  
  .menu_container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border: 1px solid #d8dbe1;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    padding: 0.4rem 0rem;
    cursor: pointer;
    z-index: 10;
    width: auto;
  }
  .menu_item {
    box-sizing: border-box;
    display: inline-flex;
    width: 100%;
    padding: 0.45rem;
    white-space: nowrap;
  }
  .menu_item:hover {
    background-color: rgba(231, 231, 231, 0.762);
  }