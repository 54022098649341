.lead-fields-layout {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.visible-lead-field {
  transition: flex-basis 0.3s, background-color 0.3s;
  display: flex;
  flex-basis: 48%;
  height: 2.5rem;
  border-radius: 5px;
  width: 48%;
  background-color: white;
}

.selected-lead-card {
  border: 1px solid #878fde;
}

.expanded-lead-card {
  flex-basis: 98%;
}

.lead-card-slide-icon {
  position: absolute;
  right: -0.6rem;
  top: 25%;
}

.lead-card-drag-indicator {
  position: absolute;
  left: -0.7rem;
  top: 25%;
  background-color: #878fde;
  border-radius: 5px;
}

.visible-empty-lead-action {
  background-color: #f0f0f0;
  padding: 15px;
  border-radius: 5px;
}

.icon-picker-items {
  background-color: #ebecfa;
  border-radius: 8px;
  padding: 0.6rem;
}
.icon-picker-items:hover {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.24);
  background-color: var(--primary) !important;
}
.icon-picker-items img {
  filter: invert(14%) sepia(81%) saturate(3569%) hue-rotate(235deg)
    brightness(93%) contrast(87%);
}

.icon-picker-items:hover img {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(162deg)
    brightness(101%) contrast(104%);
}

.lead-actions-icon {
  filter: invert(14%) sepia(81%) saturate(3569%) hue-rotate(235deg)
    brightness(93%) contrast(87%);
}

.lead-available-card-fields {
  box-shadow: 0px 0px 4px 4px #1818180a;
  border-radius: 5px;
  background-color: #ffffff;
}

.available-card-icon-layout {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 0.75rem;
  gap: 1rem;
}

.lead-list-card {
  display: flex;
  height: fit-content;
  flex-direction: column;
  background: #ffffff;
  width: 20rem;
  box-shadow: 0px 0px 5.005586624145508px 0px #1818180f;
  padding: 14px;
  border-radius: 12px;
  gap: 10px;
}

.lead-actions-layout {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(4, 1fr);
}

.lead-actions-element {
  background-color: #ebecfa;
  border-radius: 5px;
  height: 2rem;
}

.lead-actions-element:hover {
  border: 1px solid #878fde;
}
.delete-lead-action {
  opacity: 0;
  transition: opacity 0.2s;
}

.lead-actions-element:hover .delete-lead-action {
  opacity: 1;
  cursor: pointer;
  background: var(--R100);
}

.lead-card-avatar {
  position: absolute;
  top: -2.5rem;
}

