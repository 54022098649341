.lsq-button {
  box-sizing: border-box !important;
  gap: 4px !important;
  border-radius: 8px !important;
  border: none !important;
  padding: 7px 12px !important;
  outline: none !important;
  justify-content: center !important;
}

.p-button-label {
  font-family: "Nunito", sans-serif !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 0.875rem !important;
  line-height: 1.188rem !important;
  flex-grow: 0 !important;
}
.p-button:focus {
  box-shadow: none !important;
}
.outline {
  background-color: inherit !important;
  color: #314361 !important;
  border: 1px solid #314361 !important;
}
.outline:hover {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.24);
  background-color: #314361 !important;
  color: #ffffff !important;
}

.filled {
  background: #314361 !important;
  color: #ffffff !important;
  border: 1px solid #314361 !important;
}

.filled:hover {
  background: #43536f !important;
  color: #ffffff !important;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.24) !important;
}
.outline > svg {
  fill: #314361 !important;
}
.outline:hover path {
  fill: #ffffff !important;
}

.outline:disabled {
  background: #c4c9d2 !important;
}

.filled:active {
  background: #0a1f43 !important;
  box-shadow: none !important;
}
.filled:disabled {
  background: #c4c9d2 !important;
  box-shadow: none !important;
  cursor: not-allowed;
}

.text {
  background-color: #ffffff !important;
  color: #314361 !important;
  padding: 8px 12px !important;
}

.text:hover {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12) !important;
}

.text:active {
  background: #fafbfb !important;
  color: #c4c9d2 !important;
}

.text:disabled {
  background-color: #c4c9d2 !important;
}

.lsq-button:disabled {
  border: 1px solid #c4c9d2 !important;
  cursor: not-allowed !important;
  opacity: 0.5 !important;
}

.p-button.p-button-icon-only {
  width: auto !important;
}

.p-button.p-button-icon-only > span {
  display: none !important;
}

.filledV2 {
  background: #2F3237 !important;
  color: #EFEFF0 !important;
  border: 1px solid #EFEFF0 !important;
  justify-content: flex-start !important;
  gap : 0.6rem !important
}

.filledV2 > svg path{
  fill: #EFEFF0 !important;
}

.filledV2:hover {
  color: #ffffff !important;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.24) !important;
}
