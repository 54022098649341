.homepages-view {
  border: 1px solid #f5f6f8;
  border-radius: 0.5rem;
  box-shadow: 0px 2px 8px 0px #00000014;
}

.action-bar{
  border-bottom: 1px solid var(--N30);
}

.title-bar {
  border-bottom: 1px solid var(--N60);
}
.dashboard-card{
  background-color: var(--N20);
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  position: relative;
}

.card-container {
  box-sizing: border-box;
  width: 12.313rem;
  border-radius: 0.5rem;
  margin-top : -1.563rem;
}

.card-main-container {
  background: #ffffff;
  padding: 0.55rem;
  white-space: nowrap;
  height: 3.775rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem; 
}

.title-name {
  color: #43536f;
  cursor: default;
}

.active-card {
  border-left: 5px solid;
  border-left-color: rgb(49, 67, 97);
  border-radius: 0.5rem;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.24);
}

.teams-container {
  padding: 4px;
  border-radius: 0.5rem;
  gap : 0.3rem;
}

.status-style {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  margin-right: 0.4rem;
}

.transaparent-status {
  background: transparent;
}

.green-status {
  background: #169216;
}

.red-status {
  background: #e54242;
}

.orange-status {
  background: #ffb200;
}

.title-icon path {
  fill: #43536f;
}

.active-star:hover path {
  fill: #ffc338;
}

.dashboard-card-thumnail-container{
  background-color: var(--N20);
  height: 150px;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  justify-content: center;
  overflow: scroll;
}

.bulk-selection{
  width: 12.313rem;
}

.bulk-selection-card{
  box-shadow : 2px 4px 4px 0px #34405436;
  border-radius : 8px;
}

.card-action-panel{
  border-top-left-radius : 0.5rem ;
  padding : 0.25rem 0.5rem 0.25rem 0.5rem ;
}
.container {
  position: absolute;
  margin-left: -6.2rem;
  z-index: 10000;
}

.menu_container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #d8dbe1;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  padding: 0.4rem 0rem;
  cursor: pointer;
  z-index: 10;
}
.menu_item {
  box-sizing: border-box;
  width: 7.5rem;
  padding-top: 0.45rem;
  padding-bottom: 0.45rem;
  padding-left: 1rem;
}
.menu_item:hover {
  background-color: rgba(231, 231, 231, 0.762);
}
.more-icon path {
  fill: #c4c9d2;
}

.dashboard-card-actions {
  border: none;
}
.dashboard-card-actions > div:last-child {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.launchbar-tabular-view {
  border: 1px solid #f5f6f8;
  border-radius: 0.5rem;
  box-shadow: 0px 2px 8px 0px #00000014;
}