.warningWrapper {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    background-color: rgba(255, 237, 213, 1);
    padding: 9px 6px;
    border-radius: 8px;
    max-width: 400px;
    border: 1px solid #FFE5AA;
  }
  
  .icon {
    color: #f59e0b;
    flex-shrink: 0;
    margin-top: 2px;
    height: 13px;
    width: 13px;
  }
  
  .content {
    display: flex;
    flex-direction: column;
  }
  
  .title {
    font-weight: 700;
    color: #1f2937;
    font-size: 10px;
    line-height: 13px;
  }
  
  .message {
    color: #4b5563;
    font-size: 10px;
    margin: 0;
    line-height: 13px;
  }
  
  