.float-label {
    position: relative;
    padding: 0px 5px;
    top: 12px;
    left: 10px;
    z-index: 2;
    background-color: white;
    width: max-content;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.chip {
    background: #F5F6F8 !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    padding: 0px 8px;
}

