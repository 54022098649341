.widget-configuration-right-panel {
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;
  padding: 16px;
  height: 100%;
}
.widget-configuration-right-panel .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.widget-configuration-right-panel .header .delete {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: var(--R100);
  height: 32px;
  width: 32px;
}
.widget-configuration-right-panel .header .delete svg {
  fill: var(--R700);
  width: 1.2rem;
}

.widget-configuration-right-panel .configuration {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 8px;
  overflow-y: scroll;
  height: 100%;
}
.save-cancel {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  width: 100%;
}
