.lsq-input.p-dropdown .p-dropdown-label.p-placeholder {
  font-family: "Nunito", sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 0.75rem !important;
  line-height: 1rem !important;
  letter-spacing: -0.02em !important;
  color: #b0b7c2;
}

.lsq-dropdown-input-text {
  font-family: "Nunito", sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 0.75rem !important;
  line-height: 1rem !important;
  display: flex;
  align-items: flex-end;
  letter-spacing: -0.02em;
  color: #283a5a;
}

.lsq-input .pi-chevron-down{
    color: #768196;
    font-size: 0.7rem;
}

.p-dropdown .p-dropdown-clear-icon{
  color: #768196 !important;
  font-size: 0.8rem;
  margin-top: -0.4rem !important;
}

.p-dropdown-filter-container .p-inputtext:enabled:focus{
  box-shadow: none;
}

.p-dropdown-filter-container .p-inputtext{
  font-size: 0.75rem !important;
  font-family: 'Nunito', sans-serif;
  height: 2.5rem !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message{
  justify-content: center;
  font-size: 0.75rem;
  font-family: 'Nunito', sans-serif;
  display: flex;
  align-items: center;
  padding: 0 !important;
}
.lsq-dropdown .p-treeselect:not(.p-disabled):hover {
  border-color: var(--N600) !important;
}
.lsq-dropdown .p-dropdown:not(.p-disabled):hover {
  border-color: var(--N600) !important;
}
.lsq-dropdown .p-dropdown:not(.p-disabled).p-focus {
  border-color: var(--N600) !important;
}