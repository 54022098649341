.lead-page-preview-backgrond {
  width: 25rem;
  background: rgba(255, 255, 255, 0.5);
  height: 95%;
  border-radius: 0 0 20px 20px;
  overflow: hidden;
}

.lead-page-preview {
  opacity: 0;
  transition: opacity 0.2s;
}

.lead-page-img-preview {
  opacity: 1;
}

.lead-menu-item-background {
  width: 25rem;
  height: 100%;
}

.lead-menu-item-preview {
  width: 100%;
  background: rgba(255, 255, 255, 0.5);
  height: 95%;
  border-radius: 20px 20px 0 0;
}

.lead-menu-img {
  border-radius: 20px 20px 0 0;
}

.lead-menu-items {
  height: 3rem;
  background-color: var(--N0);
  box-shadow: 0px 3px 6px 0px var(--N30);
  overflow-x: scroll;
  scroll-behavior: smooth;
}
.lead-menu-item {
  border-radius: 8px;
  max-width: 10rem;
}
.lead-menu-item:hover {
  border: 1px solid #878fde;
}
.delete-lead-menu {
  opacity: 0;
  display: none;
  transition: opacity 0.2s;
  padding: 0.1rem;
}

.lead-menu-item:hover .delete-lead-menu {
  opacity: 1;
  cursor: pointer;
  background: var(--R100);
}
