.widgetNameWrapper, .addIconWrapper{
  border: 1px solid transparent;
}

.widgetNameWrapper:not(:last-child){
  margin-bottom: 2px;
}

.widgetNameWrapper:hover{
  border: 1px solid var(--N400);
  border-radius: 6px;
}

.widgetNameWrapper .addIconWrapper{
  background: var(--N20);
}


.widgetNameWrapper:hover .addIconWrapper{
  border: 1px solid var(--N400);
  border-radius: 6px;
  background: var(--N400);
}

.widgetNameWrapper:hover .addIconWrapper svg{
  fill: #fff;
}