.imageCropperWrapper .sliderWrapper{
    width: 50px !important;
    flex-grow: 1;
}

.buttonsWrapper{
    position: absolute;
    background: #fff;
    padding: 18px;
    bottom: 0px;
    right: 0px;
    z-index: 10;
}