.smartview-block{
    display: grid;
    padding-top: 2rem;
    padding-bottom: 0.2rem;
    gap: 0.5rem;
}

.tab-count-1{
    grid-template-columns: auto;
    grid-column-start: 1;
    grid-column-end: 2;
}

.tab-count-2,.tab-count-4{
    grid-template-columns: 1fr 1fr;
    grid-column-start: 1;
    grid-column-end: 3;
}

.tab-count-3{
    grid-template-columns: repeat(3, 1fr);
    grid-column-start: 1;
    grid-column-end: 4;
}

.tab-count-5{
    grid-template-columns: repeat(6, 1fr);
}

.tab-count-5 .smartview-tab-view:nth-child(-n + 2){
    grid-column: span 3;
  }
  
.tab-count-5 .smartview-tab-view:nth-last-child(-n + 3){
    grid-column: span 2;
}

.smartview-tab-view{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 8px;
    border: 1px solid var(--N30);
    padding: 0.5rem;
    position: relative;
}



.smartview-tab-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.5rem;
    width: 1.5rem;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 8px;
    position: absolute;
    right: -1px;
    top: -1px;
    font-size: 0.7rem;
}

.carousel-pagination-block{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0.3rem;
}

.carousel-pagination-block .carousel-pagination-view{
    display: flex;
    flex-direction: row;
    gap: 0.3rem
}

.circle{
    display: flex;
    height: 0.3rem;
    width: 0.3rem;
    border-radius: 50%;
    background-color: var(--N60);
}

.circle.active{
    background-color: var(--primary);
}