.lsq-inputv2 {
  font-family: "Nunito", sans-serif !important ;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
  width: 100%;
  height: 100%;
  background: #f5f6f8 !important;
  border-radius: 0.5rem !important;
  border-color: #f5f6f8 !important;
}

.lsq-inputv2::placeholder {
  font-family: "Nunito", sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
  line-height: 1.313rem !important;
  letter-spacing: 0.022rem !important;
  color: #B0B7C2 !important;
}

.lsq-inputv2.p-inputtext:enabled:focus {
  box-shadow: #43536f !important;
}
.p-input-icon-left>svg, .p-input-icon-right>svg{
  position: absolute !important;
  top: auto !important;
  margin-top: 0 !important;
  cursor: pointer;
}
.p-input-icon-right>svg {
  right: 1.8rem !important;
}

.p-input-icon-left > svg path, .p-input-icon-right > svg path {
  fill : var(--N100);
}