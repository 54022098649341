body.active-modal {
  overflow: hidden;
}

.template_background {
  position: absolute;
  height: 100%;
  width: 100%;
  background: var(--N0);
  animation: fadein 0.4s;
  z-index: 5;
}

.side-popup {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: all 0.4s ease-in-out;
  position: absolute;
  gap: 20px;
  left: -100%;
}

.side-popup.open {
  left: 0;
}

.version-panel-backdrop {
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(2px);
  width: inherit;
}

.version-panel {
  background: var(--N0);
  box-shadow: 0px 3px 14px rgba(49, 67, 97, 0.24);
  border-radius: 12px;
  overflow: hidden;
  width: 75%;
  height: 95%;
  animation: fadein 0.8s;
}

@keyframes fadein {
  0% {
    opacity: 0;
    transform: translateY(-50px, -50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.version-panel-header {
  border-bottom: 1px solid var(--N60);
}
.version-panel-cross-icon {
  background-color: var(--N0);
  box-shadow: 0px 2px 8px 0px #0000001f;
  border-radius: 6px;
}
.version-card {
  height: 3.5rem;
  cursor: pointer;
  padding: 0.6rem;
}
.version-card:hover {
  border-radius: 0.625rem;
  box-shadow: 0px 3px 14px 0px #3143611f;
}
