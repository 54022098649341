.widgetcontainer {
  padding: 1rem;
  width: 25rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.title-bar {
  border-bottom: 1px solid var(--N40);
}
.widget-details {
  flex-wrap: wrap;
}
.widget-details .widget-details-item{
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.widget-details .colSpan-1{
  flex-basis: 50%;
}
.widget-details .colSpan-2{
  flex-basis: 100%;
}

