.error-box {
  height: 45%;
  width: 40%;
  background-color: #f5f6f8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.display-text-1 {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 4rem;
  line-height: 4rem;
  color: #d8dbe1;
}

.display-text-2 {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.313rem;
  line-height: 1.813rem;
  color: #43536f;
}
