.direction-box{
    position: relative;
    height: 3.5rem;
    width: 3.5rem;
    background-color: #015CCD;
    border-radius: 0.5rem;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 2;
}

.disabled{
    background-color: #B0B7C2;
    pointer-events: none;
    opacity: 0.8;
}

.item-box-active{
    height: 500px;
    width: 200px;
    background-color: gray;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.item-box-1-left{
    height: 400px;
    width: 200px;
    background-color: gray;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.4;
    transform: translateX(50%);
}
.item-box-1-right{
    height: 400px;
    width: 200px;
    background-color: gray;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.4;
    transform: translateX(-50%);
}

.item-box-2-left{
    height: 300px;
    width: 200px;
    background-color: gray;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.2;
    transform: translateX(100%);
}
.item-box-2-right{
    height: 300px;
    width: 200px;
    background-color: gray;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.2;
    transform: translateX(-100%);
}

.carousel-box{
    position: relative;
}

.buttons-box{
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    display: flex;
    transform: translate(-50%, -50%);
    /* gap: 10%; */
    width: 40%;
    justify-content: space-between;
}

@keyframes carousel-item-right{
    0%{
        opacity: 0.2;
    }
    100%{
        opacity: 1;
    }
}

@keyframes carousel-item-left{
    0%{
        opacity: 0.2;
    }
    100%{
        opacity: 1;
    }
}

.lsq-carousel-right{
    animation-name: carousel-item-right;
}

.lsq-carousel-left{
    animation-name: carousel-item-left;
}

.lsq-carousel{
    animation-duration: 500ms;
    animation-timing-function: ease;
}
.lsq-carousel.active{
    opacity: 1;
    transition-duration: 5s;
    
}

