.teamTreePanel .p-treeselect-header {
  display: flex !important;
  background-color: #ecedf0 !important;
}

.teamTreePanel .p-treeselect-close {
  display: none !important;
}

.lsq-inputv2 {
  font-family: "Nunito", sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
  line-height: 1.375rem !important;
  width: 100%;
  height: 100%;
  background: #f5f6f8 !important;
}

.lsq-inputv2::placeholder {
  font-family: "Nunito", sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
  line-height: 1.375rem !important;
  letter-spacing: 0.022rem !important;
  color: #43536f !important;
}

.p-tree {
  padding: 0.5rem !important;
}

.pi-minus:before {
  display: none !important;
}

::-webkit-scrollbar {
  width: 8px !important;
}

::-webkit-scrollbar-track {
  background: transparent !important;
}

::-webkit-scrollbar-thumb {
  background: #d8dbe1 !important;
  border-radius: 8px !important;
}

.tree-select-v2 ~ .gap-1 {
  gap: 4px !important;
}

.teamTreePanel .p-treenode-content {
  padding: 2px 2px !important;
  margin: 0px !important;
}

.teamTreePanel .p-treenode-children {
  padding: 0px 0px 0px 0.5rem !important;
}

.teamTreePanel .p-treenode-label {
  font-family: "Nunito", sans-serif !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 0.75rem !important;
  line-height: 1rem !important;
  color: #283a5a !important;
  width: 100%;
}

.p-treeselect-label-container {
  height: 2.5rem !important;
}

.p-treeselect-label {
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
}

.p-chip-text {
    font-family: "Nunito", sans-serif !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 0.625rem !important;
    line-height: 0.875rem !important;
  color: #004578 !important;
}

.lsq-tree-select .pi-chevron-down {
  color: #768196 !important;
  font-size: 0.7rem;
}

.p-highlight {
  background-color: #ffffff !important;
}

.teamTreePanel .p-treenode-leaf .p-treenode-label {
    font-family: "Nunito", sans-serif !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 0.75rem !important;
    line-height: 1rem !important;
}

.teamTreePanel .p-checkbox {
  align-items: center !important;
  margin-right: 3px !important;
}

.teamTreePanel .p-checkbox .p-checkbox-box {
  border-radius: 3px !important;
  height: 18px !important;
  width: 18px !important;
  border: 1px solid #627087 !important;
}
.teamTreePanel .p-checkbox-box.p-highlight {
  background-color: #314361 !important;
  border-color: #314361 !important;
}

.teamTreePanel .p-treenode-leaf .p-checkbox-box.p-highlight {
  background-color: #314361 !important;
  border-color: #314361 !important;
}

.pi-fw {
  font-size: 0.7rem !important;
}

.p-treenode-content:focus {
  box-shadow: none !important;
}

.p-tree-toggler {
  pointer-events: all !important;
  margin-right: 2px !important;
}

.p-tree-toggler:focus {
  box-shadow: none !important;
}

.lsq-inputv2.tree-select-v2 .p-treeselect-trigger-icon {
  display: none !important;
}

.teamTreePanel li.p-treenode {
  opacity: 1 !important;
}

.teamTreePanel .p-treeselect-filter-container .p-inputtext {
  height: 2.5rem !important;
  background-color: #ffffff !important;
  font-size: 0.75rem !important;
  font-family: "Nunito", sans-serif;
}
