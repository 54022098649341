.launch-bar-right-panel .laucher-add-item-button {
  display: flex;
  border: 1px dashed #afb1b6;
  padding: 8px;
  justify-content: center;
  border-radius: 6px;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  cursor: pointer;
  margin: 12px 6px;
}

.launch-bar-right-panel .add-launch-item-config {
  box-shadow: 0px 2px 8px 0px #00000014;
  padding: 16px 12px 12px;
  border-radius: 6px;
  gap: 16px;
  border: 1px solid var(--N30)
}
.team-assignment-badge i{
  color: var(--N400);
}
.team-assignment-badge{
  background-color: inherit !important;
}
.lsq-button:hover .team-assignment-badge i{
  color: var(--N0);
}
