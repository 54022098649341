.tab-container {
  display: flex;
  padding: 4px;
  border-radius: 8px;
  background: var(--N30);
  flex-grow: 1;
}

.tab {
  display: flex;
  width: 100%;
  background-color: transparent;
  padding: 5px 0px;
  justify-content: center;
  cursor: pointer;
  transition: left 0.3s ease;
}

.tab.active {
  border-radius: 6px;
  background: var(--N0);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
}

.tab.disabled {
  cursor: text;
}
