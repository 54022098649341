.recommendations-container {
  border-radius: 8px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header img{
    background:  var(--Y100);
    border-radius: 2px;
    width: 18px;
    height: 18px;
}
.header i{
    color: var(--N200);
}

.collapse-icon {
  cursor: pointer;
  font-size: 20px;
  user-select: none;
  padding: 5px;
}

.points-list {
  list-style-type: none;
  padding-left: 20px;
  margin: 0;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  font-size: 0.625rem;
  line-height: 0.875rem;
}

.points-list.expanded {
  max-height: 200px;
  opacity: 1;
  overflow: scroll;
}

.points-list.collapsed {
  max-height: 0;
  opacity: 0;
}

.points-list li {
  position: relative;
  color: var(--N200);
}

.points-list li::before {
  content: "•";
  position: absolute;
  left: -15px;
}

.points-list li:last-child {
  margin-bottom: 0;
}
