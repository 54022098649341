.border-shadow {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    width: 70%;
}


.box-animation{
    transition: 800ms;
}

.fade-in{
    opacity: 1;
    visibility: visible;
}

.fade-out{
    opacity: 0;
    visibility: hidden;
    transform: translateY(-100%);
}

.side-fade-In {
  transition: all ease 500ms;
   margin-left: 4rem;
}
.side-fade-Out {
   transition: all ease 500ms;
   margin-left: 0rem;
}

.version-icon {
  background-color:  var(--N20);
  border-radius: 6px;
}

.version-icon:hover {
  background-color: var(--N0);
  box-shadow: 0px 2px 8px 0px #0000001F;
}
