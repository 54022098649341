.menu-configurator {
  padding: 0rem 0.8rem;
  background-color: var(--N20);
  position: relative;
}

.menu-configurator-header {
  border-bottom: 2px solid var(--N40);
  z-index: 1;
  padding: 0rem 1rem;
}

.menu-configurator-tab {
  background-color: #ffffff;
  border-radius: 10px;
}

.vertical-divider {
  height: 95%;
  border: 1px solid var(--N40);
}

.custom-menu-backdrop {
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(2px);
  width: inherit;
}

.custom-menu {
  background-color: var(--N0);
  width: 18rem;
  border-radius: 8px;
  border: 1px solid var(--N30);
  box-shadow: 0px 0px 16px 0px #0000001f;
}

.mobile-screen.disabled {
  pointer-events: none;
}

.status-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: var(--N0);
  width: 100%;
  height: 4%;
  min-height: 18px;
  padding: 1% 0;
}

.status-bar-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: transparent;
  height: 100%;
  margin-right: 4%;
  width: min-content;
  column-gap: 2px;
}

.time {
  font-family: "Segoe UI", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  color: #0a1f43;
  display: flex;
  align-items: flex-end;
  width: min-content;
  justify-content: center;
  margin-left: 4%;
}

.title-bar {
  display: flex;
  width: 100%;
  background-color: #ffffff;
  height: 10%;
  flex-direction: row;
}

.home-text {
  font-family: "Segoe UI", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  font-size: 1rem;
  color: #0a1f43;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tile-block {
  display: grid;
  gap: 0.5rem;
  width: 100%;
  height: fit-content;
}

.tab-count-1 {
  grid-template-columns: auto;
  grid-column-start: 1;
  grid-column-end: 2;
}

.tab-count-2,
.tab-count-4 {
  grid-template-columns: 1fr 1fr;
  grid-column-start: 1;
  grid-column-end: 3;
}

.tab-count-3 {
  grid-template-columns: repeat(3, 1fr);
  grid-column-start: 1;
  grid-column-end: 4;
}

.tab-count-5 {
  grid-template-columns: repeat(6, 1fr);
}

.tab-count-5 .tile-view:nth-child(-n + 2) {
  grid-column: span 3;
}

.tab-count-5 .tile-view:nth-last-child(-n + 3) {
  grid-column: span 2;
}

.tile-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid var(--N30);
  padding: 0.5rem;
  position: relative;
  background-color: white;
  height: 4.5rem;
}

.tile-icon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.tile-icon-background {
  padding: 0.75rem;
  margin-left: 5px;
  border-radius: 50%;
  background-color: var(--N20);
  top: 0.4rem;
  right: 0.3rem;
}

.toast_container {
  position: absolute;
  width: 14.563rem;
  right: 0.4rem;
  top: -2rem;
}

.menu-tabular-view {
  border: 1px solid #f5f6f8;
  border-radius: 0.5rem;
  box-shadow: 0px 2px 8px 0px #00000014;
}

.icon-placeholder path{
   fill: var(--N60);
}
