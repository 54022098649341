.drag_element_label {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 0.875rem;
  letter-spacing: -0.02em;
}

.drag_element {
  background-color: #f5f6f8;
  border-radius: 8px;
  padding: 0.4rem 0.1rem;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}
