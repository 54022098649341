.wrapper{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 25px;
  align-items: center;
}

.label{
  font-weight: 400;
  font-size: 16px;
  color: black;
  font-family: 'Poppins', sans-serif;
  font-style: "normal";
  letter-spacing: "-0.02em";
  align-self: center;
}

