.lsq-multiselect-panel {
  font-family: "Nunito", sans-serif !important;
}

.lsq-multiselect-panel .p-multiselect-header {
  display: none !important;
}

.lsq-multiselect {
  font-family: "Nunito", sans-serif !important;
}

.lsq-multiselect .p-multiselect-label {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 0.75rem !important;
  line-height: 1rem !important;
  letter-spacing: -0.02em !important;
  color: var(--N500) !important;
}

.lsq-select-all-panel .p-multiselect-items{
  padding: 0 !important;
}

.lsq-multiselect-panel
  .p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-empty-message {
  display: flex;
  align-items: center;
  padding: 0 !important;
}

.lsq-multiselect-panel .p-multiselect-item{
  padding: 0.5rem 1rem !important;
}

.lsq-multiselect-panel .p-multiselect-item:focus{
    box-shadow: none !important;
  }

.lsq-input .pi-chevron-down {
  color: var(--N90);
  font-size: 0.7rem;
}
.p-dropdown-filter-container .p-inputtext:enabled:focus {
  box-shadow: none;
}

.p-dropdown-filter-container .p-inputtext {
  font-size: 0.75rem !important;
  font-family: "Nunito", sans-serif;
  height: 2.5rem !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
  justify-content: center;
  font-size: 0.75rem;
  font-family: "Nunito", sans-serif;
}

.lsq-multiselect-panel .p-checkbox{
    margin-right: 0rem !important;
}

.lsq-multiselect-panel .p-checkbox .p-checkbox-box{
    height: 12px !important;
    width: 12px !important;
}

.lsq-multiselect-panel .p-checkbox .p-checkbox-icon{
    font-size: 0.6rem !important;
}

.lsq-multiselect-panel .p-checkbox .p-checkbox-box.p-highlight{
    border-color: var(--primary) !important;
    background-color: var(--primary) !important;
}