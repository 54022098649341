
.iframeWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    pointer-events: none;
}
.standard {
    height: 30vh
}
.expanded {
    height: 60vh;
}
.useFullWidth {
    border: none;
}
