.configParentWrapper {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
    padding: 12px;
}

.configSubWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    width: 100%;
    padding: 10px 0px;
    position: relative;
    align-content: flex-start;
    z-index: 2;
}

.previewImage {
    width: 100%;
}

.title {
    color: var(--N600);
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    border-bottom: 1px solid #EFEFF0;
    padding-bottom: 10px;
}


.disbaleEditTransparentLayer {
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    top: 0;
    left: 0;
    z-index: 3;
}

.itemSubWrapper {
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
    height: 100%;
    overflow: hidden;
    padding: 8px;
}

.itemWrapper {
    box-sizing: content-box;
    border: 3px solid transparent;
    border-radius: 8px;
}

.itemWrapper:hover {
    border: 3px solid var(--S600)
}

.horizonatalResizer {
    display: flex;
    align-items: center;
    position: absolute;
    right: -5px;
    top: 0;
    bottom: 0;
    width: 8px;
}


.horizonatalResizer>div {
    display: none;
    height: 8px;
    width: 8px;
    border-radius: 100%;
    background: var(--S600);
    cursor: ew-resize;
}

.itemWrapper:hover .horizonatalResizer>div {
    display: block;
}

.verticalResizer {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -3px;
    height: 6px;
}

.verticalResizer>div {
    display: none;
    height: 8px;
    width: 8px;
    border-radius: 100%;
    background: var(--S600);
    cursor: ns-resize;
}

.itemWrapper:hover .verticalResizer>div {
    display: block;
}

.iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 23px;
    width: 23px;
    min-width: 23px;
    border-radius: 5px;
    background: var(--S100);
}

.iconWrapper img {
    width: 18px;
}

.itemHeading {
    font-size: 9.7px;
    margin-top: 6px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.minHeightWrapper div[class*='itemSubWrapper'] {
    padding: 4px 8px;
    flex-direction: row;
    align-items: center;
}

.minHeightWrapper div[class*='itemHeading'] {
    white-space: nowrap;
    align-items: center;
    margin-left: 6px;
    margin-top: auto;
    margin-bottom: auto;
}

.maxHeightWrapper div[class*='itemHeading'] {
    height: 24px;
    line-height: 12px;
   
    display: -webkit-box;
    line-clamp: 2;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.skeleton {
    background: #f0f0f0;
    border-radius: 8px;
    margin-bottom: 10px;
    animation: pulse 1.5s ease-in-out infinite;
}

.fullWidth {
    width: 100%;
}

.halfWidth {
    width: 50%;
}

.small {
    height: 36px;
}

.medium {
    height: 69px;
}

.row {
    display: flex;
    gap: 10px;
}

.loaderWrapper {
    padding: 12px;
    overflow: hidden;
    height: calc(100% - 12px);
}

.imageWrapper{
    overflow: auto;
    height: 100%;
}

@keyframes pulse {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

.activeResizer {
    background-color: var(--S100);
    transition: background-color 0.2s;
    outline: 3px solid var(--S600);
  }