.multi-range-slider .labels {
  display: none;
}
.multi-range-slider .thumb::before {
  box-shadow: none;
  border-radius: 4px;
  width: 6px;
  height: 16px;
  background-color: white;
  border-width: 2px;
  margin: -6px -6px;
}
.multi-range-slider .thumb.thumb-left::before {
  border-color: rgb(251, 68, 68);
}
.multi-range-slider .thumb.thumb-right::before {
  border-color: var(--Y600);
}
