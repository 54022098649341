body.active-modal {
  overflow: hidden;
}

.promotional-banner-box {
  background: #ffffff;
  box-shadow: 0px 3px 14px rgba(49, 67, 97, 0.24);
  border-radius: 12px;
  overflow: hidden;
  padding: 2rem;
  width: 60vw;
  animation: fadein 0.8s;
}

@keyframes fadein {
  0% {
    opacity: 0;
    transform: translateY(-50px, -50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.promotional-banner {
  z-index: 10;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
}

.promotional-banner-images {
  box-shadow: 0px 3px 14px 0px #3143611f;
  border-radius: 12px;
}
