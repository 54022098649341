.status-template.p-tag {
  background: #e6fee8;
}
.status-template {
  color: #04a410;
}
.status-template.p-tag.published {
  background: #e6fee8;
}
.status-template.published {
  color: #04a410;
}
.status-template.unpublished.p-tag {
  background: #ffe6e6;
}
.status-template.unpublished {
  color: #fa0303;
}
.status-template.draft.p-tag {
  background: var(--N20);
}
.status-template.draft {
  color: var(--N400);
}
