.toast-container {
  border-radius:  12px;
  box-sizing: border-box;
  width: auto;
  height : auto;
  max-width: 14.563rem;
  padding: 8px 12px 8px 8px;
  animation-name: fadeIn;
  animation-duration: 300ms;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  animation-delay: 0ms;
  z-index: 1;
}

.toast-heading {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
}

.warning {
  border: 1px solid rgba(255, 212, 113, 1);
  border-left: 4px solid rgba(255, 212, 113, 1);
  box-shadow: 0px 0px 12px rgba(255, 182, 14, 0.25);
}

.error {
  border: 1px solid rgba(236, 118, 118, 1);
  border-left: 4px solid rgba(236, 118, 118, 1);
  box-shadow: 0px 0px 12px rgba(235, 87, 87, 0.3);
}

.success {
  border: 1px solid rgba(125, 202, 125, 1);
  border-left: 4px solid rgba(125, 202, 125, 1);
  box-shadow: 0px 0px 12px rgba(51, 158, 51, 0.24);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: translateY(2rem);
  }
}