.tooltip-body{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: var(--N0);
    border-radius: 12px;
    padding: 0.6rem 1rem;
    max-width: 300px;
    min-width: 200px;
}

.tooltip-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0.2rem;
}

.tooltip-footer{
    display: flex;
    flex-direction: row-reverse;
    margin-top: 1rem;
    justify-content: space-between;
}

.tooltip-footer .tooltip-actions{
    display: flex;
    flex-direction: row;
    gap: 0.3rem;
}