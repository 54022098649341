.accordion-container {
  height: fit-content;
  width: 100%;
  border-bottom: 1px solid var(--N40);
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.collapsed-view {
  height: 0;
  display: none;
}

.expanded-view {
  height: 100%;
  transition: all 0.6s ease-in;
}

.icon-style {
  background: var(--N20);
  border-radius: 8px;
  padding: 1rem;
}
