.p-menu{
    padding: 0 !important;
    border-radius: 12px !important;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25) !important;
}
.p-menu-separator{
    width: 70% !important;
    border-top: 1px solid var(--B200) !important;
    margin: auto !important;
}

.widget-view-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    padding: 0.1rem 0rem;
    background: #FFFFFF;
}