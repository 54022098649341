.lsq-input-textarea {
  font-family: "Nunito", sans-serif !important;
}

.lsq-input-textarea .p-inputtext {
  border: none !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 0.938rem !important;
  line-height: 1.279rem !important;
  display: flex;
  letter-spacing: -0.02em;
  color: var(--N500);
  width: 100%;
  height: 25vh;
}

.lsq-input-textarea.p-inputtext .p-placeholder {
  color: var(--N40);
}
