.launcher-container { 
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    padding: 0.1rem 0rem;
    background: #FFFFFF;
    gap: 0.5rem
}

.launcher-grid-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start;
    row-gap: 1.2rem;
    margin: 0.3rem 0px;
}

.launcher-element-box {
    display: flex;
    width: 33.33%;
    height: min-content;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.launcher-element-label {
    font-family: 'Segoe UI', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size:0.625rem;
    letter-spacing: -0.02em;
    text-align: center;
}
.element {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width:28%;
  max-width: 28%;
  padding-bottom: 0.6rem;
  gap: 0.25rem;
} 
.image {
  display: flex;
  padding: 0.4rem;
  border-radius: 50%;
  background-color: #ECEDF0;
  justify-content: center;
  align-items: center;
}