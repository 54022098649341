.team-assignment-badge i{
    color: var(--N400);
  }
  .team-assignment-badge{
    background-color: inherit !important;
  }
  .lsq-button:hover .team-assignment-badge i{
    color: var(--N0);
  }
  
.react-grid-item.react-grid-placeholder{
  background: #00BBDD;
}