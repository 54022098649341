.img-upload-container {
  box-sizing: border-box;
  padding: 6px;
  background: #ffffff;
  border: 1px solid #b0b7c2;
  border-radius: 6px;
}
.custom-file-upload .p-button{
background: #314361 !important;
border: 2px solid #F5F6F8 !important;
padding: 8px 12px !important;
border-radius: 0.5rem !important;
}

.custom-file-upload .p-button-icon{
    display: none !important;
}
.custom-tooltip .p-tooltip-text{
  display: flex;
  align-items: center;
  font-size: 0.725rem !important;
  border-radius: 0.5rem;
  background-color: rgba(255, 255, 255, 1) !important;
  color: rgba(67, 83, 111, 1) !important;
}

.custom-tooltip.p-tooltip-bottom .p-tooltip-arrow{
  left : 85% !important;
}