.side-panel {
  position: fixed;
  width: 30%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 3px 14px rgba(49, 67, 97, 0.24);
  transition: all 0.4s;
  z-index: 2;
}

.left {
  left: 0;
  transform: translateX(-100%);
  border-radius: 0px 12px 12px 0px;
}

.right {
  right: 0;
  transform: translateX(100%);
  border-radius: 12px 0px 0px 12px;
}

.open.right {
  transition: all 0.4s;
  transform:none;
}

.open.left {
  transition: all 0.4s;
  transform: translateX(0%);
}
