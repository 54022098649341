.carousel-container{
    width: 100%;
    margin: auto;
    position: relative;
}
.Square{
    height: 34vh;
}
.Wide{
    height: 17vh;
}
.carousel-container .p-carousel-content {
    position: relative;
}

.carousel-container .p-carousel-indicators {
    position: absolute !important;
    flex-wrap: nowrap !important;
    box-sizing: content-box !important;
    bottom: 6px;
    left: 0;
    right: 0;
    margin: auto !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    padding: 4px !important;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 8px;
}

.carousel-container .p-carousel-indicator {
    margin: 2px !important;
    border-radius: 50% !important;
}

.carousel-container .p-carousel-indicator > button {
    width: 4px !important;
    height: 4px !important;
    border-radius: 50% !important;
    background: rgba(255, 255, 255, 0.4) !important;
}

.carousel-container .p-carousel-prev,
.p-carousel-next {
    display: none !important;
}

.carousel-container .p-highlight > button {
    background-color: #fff !important;
}

.expired-stamp {
  background: rgba(49, 67, 97, 0.32);
  backdrop-filter: blur(6px);
  height: 23px;
}
