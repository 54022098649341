.lsq-color-picker.p-colorpicker {
  display: flex;
  align-items: center;
  width: auto !important;
}
.p-colorpicker-panel {
  background-color: var(--N0) !important;
  border: none !important;
  border-radius: 8px !important;
}

.p-colorpicker-preview {
  width: 1rem !important;
  height: 1rem !important;
}

.p-colorpicker-preview.p-inputtext {
  border: none !important;
}

.colour-input.p-inputtext {
  padding: 0 !important;
  border: none !important;
  border-radius: 0 !important;
  font-family: "Nunito", sans-serif !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 0.75rem !important;
  line-height: 1rem !important;
  width: 100%;
}
