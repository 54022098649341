.lsq-slider .p-slider-handle {
    position: absolute !important;
    cursor: grab !important;
    touch-action: none !important;
    display: block !important;
    z-index: 1 !important;
    background: #283A5A !important;
    border: none !important;
    width: 0.563rem !important;
    height: 0.563rem !important;
}

.lsq-slider .p-slider-handle:focus {
    outline: 0 none !important;
    outline-offset: 0 !important;
    box-shadow: none !important;
}
.lsq-slider.p-slider-horizontal {
    height: 1px !important;
    width: 110px !important;
}
.lsq-slider .p-slider-range {
    background: #283A5A !important;
}
.p-slider.p-slider-horizontal .p-slider-handle {
    margin-top: -0.3rem !important;
    margin-left: -0.1rem !important;
}