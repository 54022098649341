.teams-tabular-view {
  border: 1px solid #f5f6f8;
  border-radius: 0.5rem;
  box-shadow: 0px 2px 8px 0px #00000014;
}

.teams-tabs{
  margin-left: auto;
  margin-right: 20px;
  width: 280px;
}