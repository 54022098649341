.icon-placeholder path {
  fill: var(--N60);
}

.laucher-add-item {
  border: 1px solid #ecedf0;
}

.laucher-add-item:hover {
  box-shadow: 0px 3px 14px 0px var(--N20);
}
