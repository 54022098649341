.wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.radio-group{
    gap: 4px;
}

.gap-2{
    column-gap: 1rem;
    row-gap: 8px;
}
