.backgroundLayer{
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    z-index: 1;
}

.panelWrapper{
    position: relative;
    background: #fff;
    z-index: 2;
}