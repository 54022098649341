.drag_element {
  background-color: #f5f6f8;
  border-radius: 8px;
  padding: 0.4rem 0.1rem;
}
.selected_drag_element {
  background-color: #f5f6f8;
  border-radius: 8px;
  padding: 0.4rem 0.1rem;
  border: 1px solid #8a94a5;
}

.drag_element_disabled {
  opacity: 0.3;
  border: 1px solid #ecedf0;
  border-radius: 8px;
  background-color: #f5f6f8;
  padding: 0.4rem 0.1rem;
  pointer-events: none;
}

.drag_element_label {
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 0.875rem;
    letter-spacing: -0.02em;
}
