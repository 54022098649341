.desktop-header {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 50px;
  line-height: 70px;
  color: #0a1f43;
}
.desktop-subcontent {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.705rem;
  color: "rgba(49, 67, 97, 1)";
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.desktop-subcontent-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  height: 100vh;
  padding-bottom: 3rem;
}
.desktop-logo {
  display: flex;
  padding-left: 8px;
  flex-direction: row;
  margin-top: 3rem;
}
.desktop-logo > svg {
  transform: scale(1.3);
}

.button {
  max-width: max-content;
  height: 6rem;
  display: flex;
  align-items: center;
}
.button > button {
  white-space: nowrap;
  padding: 12px 30px !important;
}
.desktop-background {
  background: url("/public/images/casa_subscription.png");
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.desktop-content {
  width: 50vw;
  height: 100vh;
  gap: 8rem;
  padding-left: 3.5rem;
}

.booked-demo {
  box-sizing: border-box;
  background-color: #f5f6f8;
  border-radius: 12px;
  width: fit-content;
  padding: 20px 24px 20px 16px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.mobile-background {
  background-image: url("/public/images/mobile_background.png");
  height: auto;
  max-height: 70vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.mobile-content {
  display: flex;
  flex-direction: column;
  height: 100vh;
  text-align: center;
  justify-content: flex-end;
  padding: 1rem 3rem;
  gap: 0.25rem;
}
.mobile-header {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 49px;
  text-align: center;
  letter-spacing: 0.35px;
}
.mobile-subcontent {
  display: flex;
  flex-direction: column;
  gap: 20px !important;
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.35px;
  margin-bottom: 1rem;
}
.mobile-button > div > button {
  width: 100%;
  border-radius: 0.25rem !important;
}

.mobile-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobile-button > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.25rem !important;
}
.mobile-button > .booked-demo {
  display: flex;
  flex-direction: row !important;
}

@media only screen and (max-height: 800px) {
  .mobile-content {
    padding: 1rem 2rem !important;
  }
  .mobile-background {
    max-height: 55vh !important;
  }
  .mobile-header {
    font-size: 2rem !important;
    line-height: 40px !important;
  }
  .mobile-subcontent > div {
    font-size: 12px !important;
    line-height: 13px !important;
  }
  .mobile-subcontent {
    gap: 10px !important;
  }
  .mobile-button > .button > div {
    font-size: 14px !important;
  }
  .sub-header > div {
    font-size: 14px !important;
    line-height: 1rem;
  }
  .booked-demo {
    padding: 0.5rem !important;
  }
  .booked-demo-content > div {
    font-size: 12px !important;
  }
}

@media only screen and (min-height: 801px) and (max-height: 850px) { 
  .mobile-content {
    padding: 1rem 2rem !important;
  }
  .mobile-header {
    font-size: 2.2rem !important;
    line-height: 40px !important;
  }
  .mobile-subcontent > div {
    font-size: 13px !important;
    line-height: 13px !important;
  }
  .booked-demo-content > div {
    font-size: 12px !important;
  }
}
