.casa-web-view-app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  width: 100%;
  overflow: hidden;
}
.top-content {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
}
.bottom-content {
  display: flex;
  width: 100%;
  overflow: hidden;
  flex-grow: 1;
  background: var(--N30);
}
.bottom-content.web-preview-mode {
  background: #2f3237;
}
.left-panel {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: var(--N0);
  border-radius: 0px 12px 12px 0px;
}
.right-panel {
  width: 25%;
  min-width: 240px;
  transform: none;
  height: -webkit-fill-available;
  border-radius: 24px 0px 0px 24px;
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  background: white;
  height: 100%;
}
.bottom-content .web-preview {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  margin: 8px;
}
.bottom-content.web-preview-mode .web-preview {
  margin: 8px 80px;
  padding: 0px 24px;
  margin-bottom: 64px;
}
