
.buttonsWrapper {
  display: flex;
  margin-left: auto;
  gap: 0.5rem;
}

.buttonsWrapper button {
  color: #fff !important;
  border: 1px solid #fff !important;
}

.buttonsWrapper button:hover {
  color: #2f3237 !important;
  background: #fff !important;
  border: 1px solid #fff !important;
}