body.active-modal {
  overflow-y: hidden;
}

.confirm-box {
  background: #ffffff;
  box-shadow: 0px 3px 14px rgba(49, 67, 97, 0.24);
  border-radius: 12px;
  overflow: hidden;
  padding: 1rem 3rem;
  width: 30vw;
  animation: fadein 0.8s;
}

.siera-web-homepage {
  background: #ffffff;
  box-shadow: 0px 3px 14px rgba(49, 67, 97, 0.24);
  border-radius: 12px;
  overflow: hidden;
  padding: 1rem;
  width: 34vw;
  animation: fadein 0.8s;
}

@keyframes fadein {
  0% {
    opacity: 0;
    transform: translateY(-50px, -50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.modal-background{
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(22px);
  -webkit-backdrop-filter: blur(22px);
}

.suggestion-box {
  background: var(--N0);
  box-shadow: 0px 3px 14px rgba(49, 67, 97, 0.24);
  border-radius: 12px;
  overflow: hidden;
  width: 37vw;
  min-width: 27rem;
  animation: fadein 0.8s;
}
.suggestion-box-header {
  border-bottom: 1px solid var(--N40);
  padding: 0.5rem 0.8rem;
}
.suggestion-box-container {
  padding: 0.5rem 0.8rem;
}
