.status-style {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
}

.transaparent-status {
  background: transparent;
}

.green-status {
  background: #169216;
}

.red-status {
  background: #e54242;
}

.menu-card-container {
  width: 11rem;
  position: relative;
  height: 3rem;
}

.menu-card {
  box-shadow: 0px 2px 8px 0px #00000014;
  background: #ffffff;
  border-radius: 12px;
  position: absolute;
}

