.publish-box {
  background: #ffffff;
  box-shadow: 0px 3px 14px rgba(49, 67, 97, 0.24);
  border-radius: 12px;
  overflow: hidden;
  height: 85vh;
  width: calc((9 / 19) * 92vh);
  animation: fadein 0.8s;
  min-width: calc((9 / 19) * 450px);
}

.success-box {
  transition: all 0.6s ease-in;
  height: 40vh;
}

@keyframes fadein {
  0% {
    opacity: 0;
    transform: translateY(-50px, -50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.title-bar {
  border: 1px solid #ecedf0;
  padding-top: 1.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-bottom: 0.5rem;
}

.publish-content {
  padding: 1.313rem;
  gap: 1.25rem;
  overflow-y: scroll;
}
