.lead-controls-top-bar {
  padding: 10px;
  box-shadow: 0px 2px 5px 0px #0000000a;
  border-bottom: 1px solid #efeff0;
}

.lead-controls-container {
  background: #efeff0;
  margin-left: 7rem;
}

.lead-control-side-panel {
  box-shadow: 4px 0px 8px rgba(0, 0, 0, 0.04);
  background-color: #fcfcfd;
  width: 7rem;
  height: 100%;
  left: 0;
}

.lead-controls-options {
  padding: 6px;
  width: 100%;
  border: 0;
  min-height: 40px;
  justify-content: center;
  align-items: center;
}

.active-lead-controls-option {
  background: var(--N0);
  border-radius: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
}

.hamburger-icon{
  border: 1px solid #EFEFF0;
  background-color: white;
  border-radius: 5px;
  box-shadow: 4px 0px 8px rgba(0, 0, 0, 0.04)
}
.back-icon{
  border: 1px solid #EFEFF0;
  background-color: white;
  border-radius: 5px;
  box-shadow: 4px 0px 8px rgba(0, 0, 0, 0.04);
  padding:11px;
}

.lead-type{
  backdrop-filter: blur(4px);
  --webkit-backdrop-filter: blur(4px);
}

.lead-type-container{
  background-color: var(--N0);
  border-radius: 12px;
  padding: 1rem;
  width: 22rem;
  height: fit-content;
  max-height: 25rem;
  margin-left: 7rem;
}

.lead-type-error{
  background-color: var(--N0);
  border-radius: 12px;
  width: 22rem;
  height: fit-content;
  margin-left: 7rem;
}
