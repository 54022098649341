.lsq-progress {
  height: 1.125rem !important;
  width: 1.125rem !important;
}

.lsq-progress .p-progress-spinner-circle {
    stroke: currentColor;
    animation: p-progress-spinner-dash 1.5s ease-in-out infinite,
    6s ease-in-out infinite !important;
}
