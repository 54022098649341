.lsq-label{ 
    user-select: none;
    top: 0px !important;
    background: white !important;
    padding: 0 5px;
    font-family: 'Nunito', sans-serif;
}

.lsq-input input:focus ~ label{
    font-family: 'Nunito', sans-serif !important;
    top: 0px !important;
    background: white !important;
    padding: 0 5px;
}

.lsq-input{
    width: 100%;
    height: 2.5rem !important;
    font-family: "Nunito", sans-serif !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    letter-spacing: -0.02em !important;
}

.lsq-input-bold{
    width: 100%;
    height: 2.5rem !important;
    font-family: "Nunito", sans-serif !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    letter-spacing: -0.02em !important;
}

.lsq-input::placeholder{
    font-family: "Nunito", sans-serif !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    color: #B0B7C2
}

.lsq-input.p-inputtext:enabled:focus{
    box-shadow: none !important;
}
.lsq-input-bold.p-inputtext:enabled:focus{
    box-shadow: none !important;
}
