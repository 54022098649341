.menu_container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  border: 1px solid #D8DBE1;
  justify-content: center;
  align-items: center;
  width : 8rem;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  margin-top: 1rem;
  padding :0.4rem 0rem;
  cursor: pointer;
  position: absolute;
  top: 2rem;
  z-index: 9;
}
.inline_dropdown_container {
  width: 8rem;
  overflow-wrap: break-word; 
  align-items: center;
  justify-content: center;
}
.menu_item {
  width: 100%;
  box-sizing: border-box;
  padding-top: 0.45rem;
  padding-bottom: 0.45rem;
  padding-left: 0.9rem; 
  padding-right: 0.5rem;
  overflow-wrap: break-word; 
  line-height: 1rem;
}
.menu_item:hover {
  background-color: rgba(231, 231, 231, 0.762);
}

.value_template{
  box-sizing: border-box;
  overflow-wrap: break-word;
  line-height: 1rem;
}