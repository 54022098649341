.heading {
  font-family: "Segoe UI", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.3rem;
}

.sub-heading-bold {
  font-family: "Segoe UI", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
}

.sub-heading {
  font-family: "Segoe UI", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
}

.title {
  font-family: "Segoe UI", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.188rem;
}

.input-text {
  font-family: "Segoe UI", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.8rem;
  letter-spacing: -0.02em;
}

.input-text-label {
  font-family: "Segoe UI", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.625rem;
  line-height: 0.68rem;
  letter-spacing: -0.02em;
}

.H0B {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 2.125rem;
  line-height: 2.899rem;
}

.H0 {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.125rem;
  line-height: 2.899rem;
}

.H1B {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.313rem;
  line-height: 1.813rem;
}

.H1 {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.313rem;
  line-height: 1.813rem;
}

.H2B {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.563rem;
}

.H2 {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.563rem;
}

.T1B {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.375rem;
}
.T1 {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.375rem;
}

.T2B {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.938rem;
  line-height: 1.25rem;
}

.T2 {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.938rem;
  line-height: 1.25rem;
}

.T3B {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.188rem;
}
.T3 {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.188rem;
}

.T4B {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.812rem;
  line-height: 1rem;
}
.T4 {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.812rem;
  line-height: 1rem;
}

.T5B {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 0.875rem;
}

.T5 {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.875rem;
}

.T6B {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 0.625rem;
}

.T6 {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.625rem;
}

.mobile-T5 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 8.7px;
  line-height: 10px;
  text-align: center;
  letter-spacing: -0.05655px;
}

.mobile-numbers {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-size: 0.8rem;
  font-weight: 700;
  line-height: 0.813rem;
}
