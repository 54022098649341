.lsq-split-button {
  box-sizing: border-box !important;
  border-radius: 8px !important;
  border: none !important;
  outline: none !important;
  overflow: hidden !important;
}

.lsq-split-default-button {
  box-sizing: border-box !important;
  gap: 4px !important;
  padding: 7px 12px !important;
}
.lsq-split-menu-button.p-button.p-button-icon-only {
  width: 1.8rem !important;
  padding: 0 !important;
}

.lsq-split-menu-button.p-button.p-button-icon-only > span {
  display: block !important;
}

.lsq-split-button-menu.p-menu {
  width: auto !important;
  transform: none !important;
  box-shadow: none !important;
  border-radius: 8px !important;
  overflow: hidden !important;
}

.lsq-split-button-menu .p-menuitem-text {
  font-family: "Nunito", sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 0.938rem !important;
  color: #2f3237 !important;
}

.p-button-label {
  text-transform: capitalize !important;
}
